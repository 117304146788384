export default (function (app) {
    app.ports.subscribeElementVisibilityObserver.subscribe(subscribeElement);
    function subscribeElement(subscription) {
        // Giving a bit of time to make sure the component is render.
        setTimeout(function () {
            var element = document.getElementById(subscription.elementId);
            var observer = new IntersectionObserver(function (entries) {
                entries.forEach(function (entry) {
                    var event = new CustomEvent('visibilitychanged', {
                        bubbles: true,
                        detail: { hasLeftViewport: entry.isIntersecting },
                    });
                    entry.target.dispatchEvent(event);
                });
            }, { threshold: 1 });
            if (element)
                observer.observe(element);
        }, 50);
    }
});
