import { v4 as uuidv4 } from 'uuid';
import Cookie from 'js-cookie';
/**
 * Tests again user agent string if it is associated to a known mobile device.
 * WARNING: this method may or may not work accordingly to browser settings as device fingerprinting is explicitly
 * hindered by tracking prevention policies
 *
 * @returns whether the user agent is associated to a mobile device
 */
export function isMobileUserAgent() {
    return /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(navigator.userAgent);
}
/**
 * Gets the browser sticky (1 year) identifier stored in a cookie named wu_uid.
 * This function gets current wu_uuid value, if not present for some unknown reason then a new value is generated and stored
 *
 * @param isCrossDomainCookieEnabled - whether cookie can be applied in cross-domain mode (env based)
 * @param crossDomain - the domain that will be used when cross domain is enabled eg. '.prima.it'
 * @returns the uuid4 value
 */
export function getOrCreatePersistentIdentifier(isCrossDomainCookieEnabled, crossDomain) {
    var browserIdentifierCookieName = 'wu_uid';
    var browserIdentifier = Cookie.get(browserIdentifierCookieName);
    if (!browserIdentifier) {
        browserIdentifier = uuidv4().toString();
        var nextY = new Date();
        nextY.setFullYear(nextY.getFullYear() + 1);
        Cookie.set(browserIdentifierCookieName, browserIdentifier, {
            domain: isCrossDomainCookieEnabled ? crossDomain : undefined,
            expires: nextY,
            sameSite: isCrossDomainCookieEnabled ? 'none' : 'lax',
            secure: isCrossDomainCookieEnabled,
        });
    }
    return browserIdentifier;
}
