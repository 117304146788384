export default (function (datadog) {
    function sendError(elmError) {
        datadog.addError(elmError.prefix, elmError);
    }
    function sendCustomAction(elmCustomAction) {
        datadog.addAction(elmCustomAction.customAction, elmCustomAction.customData);
    }
    return {
        sendError: sendError,
        sendCustomAction: sendCustomAction,
    };
});
