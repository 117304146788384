var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Flagship, LogLevel, } from '@flagship.io/js-sdk';
var FlagshipIO = /** @class */ (function () {
    /**
     * Wraps flagship start method in a class constructor which ensure to initialize a visitor
     *
     * @param args - constructor argument record
     */
    function FlagshipIO(args) {
        var _this = this;
        this.fetchedFlagKeys = [];
        this.instance = Flagship.start(args.envId, args.apiKey, __assign(__assign({}, args.config), { 
            // Disabling deduplication
            hitDeduplicationTime: 0, logLevel: LogLevel.WARNING, onBucketingFail: function (error) {
                args.logger.error('FlagshipIO:bucketing failure', error);
            }, onBucketingSuccess: function (_a) {
                var status = _a.status, payload = _a.payload;
                args.logger.log('FlagshipIO:bucketing success', status, payload);
            }, onBucketingUpdated: function (lastUpdate) {
                args.logger.log('FlagshipIO:bucketing updated', lastUpdate);
            }, onLog: function (level, tag, message) {
                args.logger.log('FlagshipIO:log', "[".concat(LogLevel[level], "] [").concat(tag, "] : ").concat(message));
            }, statusChangedCallback: function (status) {
                args.logger.log('FlagshipIO:status changed callback', status);
            } }));
        this.logHitSending = args.logHitSending || false;
        this.logger = args.logger;
        this.visitorReadyPromise = new Promise(function (resolve, reject) {
            var v = _this.instance.newVisitor({
                context: __assign(__assign({}, args.visitorInitialContext), { userAgent: navigator.userAgent }),
                // Set to false to make sure we are not sending events if the user don't consent
                hasConsented: false,
                isAuthenticated: false,
                visitorId: args.visitorId,
            });
            v.on('ready', function (error) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    if (error) {
                        args.logger.error('FlagshipIO:visitor ready error', error);
                        reject("Visitor ready error: ".concat(error));
                    }
                    resolve(v);
                    return [2 /*return*/];
                });
            }); });
        });
    }
    /**
     * Fetch the flag from Flagship. Wraps the stuff into an async promise due to initial fetchFlags method.
     * Once the class have been correctly instanced with a visitor this method will never fail
     *
     * @param flagName - a flag name eg: "button-color"
     * @param fallbackValue - a fallback error returned in case of error eg: "red"
     * @returns a T value wrapped into flagship IFlag type
     */
    FlagshipIO.prototype.getFlag = function (flagName, fallbackValue) {
        return __awaiter(this, void 0, void 0, function () {
            var v, flag, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.visitorReadyPromise];
                    case 1:
                        v = _a.sent();
                        flag = v.getFlag(flagName, fallbackValue);
                        this.fetchedFlagKeys.push(flag);
                        return [2 /*return*/, v.getFlag(flagName, fallbackValue).getValue()];
                    case 2:
                        e_1 = _a.sent();
                        this.logger.error('FlagshipIO:getFlag', e_1);
                        return [2 /*return*/, fallbackValue];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Update visitor privacy consent
     *
     * @param hasConsented - boolean to describe if the user consented about their privacy.
     */
    FlagshipIO.prototype.updatePrivacyConsent = function (hasConsented) {
        return __awaiter(this, void 0, void 0, function () {
            var v, e_2;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.visitorReadyPromise];
                    case 1:
                        v = _a.sent();
                        v.setConsent(hasConsented);
                        if (hasConsented) {
                            // If any flag was fetched prior the consent, we need to enable the data collection
                            // of the user regarding that flag
                            this.fetchedFlagKeys.forEach(function (flag) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, flag.visitorExposed()];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); });
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        this.logger.error('FlagshipIO:updatePrivacyConsent', e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Wraps internal flagship updateContext into a promise
     *
     * @param context -  a generic JSON object with no nesting
     */
    FlagshipIO.prototype.updateContext = function (context) {
        return __awaiter(this, void 0, void 0, function () {
            var v;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.logger.error('FlagshipIO:updateContext', context);
                        return [4 /*yield*/, this.visitorReadyPromise];
                    case 1:
                        v = _a.sent();
                        v.updateContext(context);
                        return [4 /*yield*/, v.fetchFlags()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Wraps internal flagship send hit
     *
     * @param hit - flagship internal hit type
     */
    FlagshipIO.prototype.sendInternalHit = function (hit) {
        return __awaiter(this, void 0, void 0, function () {
            var v;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.logHitSending) {
                            console.log('FlagshipIO:sendInternalHit', hit);
                        }
                        return [4 /*yield*/, this.visitorReadyPromise
                            // Make sure to not send the event if the user didn't consent.
                        ];
                    case 1:
                        v = _a.sent();
                        if (!v.hasConsented) return [3 /*break*/, 3];
                        return [4 /*yield*/, v
                                .sendHit(hit)
                                .then(function () {
                                if (_this.logHitSending) {
                                    console.log('FlagshipIO:sendInternalHits sent');
                                }
                            })
                                .catch(function (err) {
                                _this.logger.error('FlagshipIO:sendInternalHits', err);
                            })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Wraps internal flagship send hits
     *
     * @param hits -  a list of flagship internal hit type
     */
    FlagshipIO.prototype.sendInternalHits = function (hits) {
        return __awaiter(this, void 0, void 0, function () {
            var v;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.logHitSending) {
                            console.log('FlagshipIO:sendInternalHits', hits);
                        }
                        return [4 /*yield*/, this.visitorReadyPromise
                            // Make sure to not send the event if the user didn't consent.
                            // Instead, we store the event in a local queue, to send them if the user accepts it later.
                        ];
                    case 1:
                        v = _a.sent();
                        if (!v.hasConsented) return [3 /*break*/, 3];
                        return [4 /*yield*/, v
                                .sendHits(hits)
                                .then(function () {
                                if (_this.logHitSending) {
                                    console.log('FlagshipIO:sendInternalHits sent');
                                }
                            })
                                .catch(function (err) {
                                _this.logger.error('FlagshipIO:sendInternalHits', err);
                            })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Mount elm App ports
     *
     * @param app - The App which implements FlagshipIO module
     */
    FlagshipIO.prototype.mount = function (app) {
        var _this = this;
        app.ports.flagshipIOPort_.subscribe(function (portValue) {
            switch (portValue.type_) {
                case 'send_hit': {
                    _this.sendInternalHit(portValue.hit)
                        .then()
                        .catch(function (err) {
                        _this.logger.error('FlagshipIO:flagshipIOPort_ send_hit', err);
                    });
                    break;
                }
                case 'send_hits': {
                    _this.sendInternalHits(portValue.hits)
                        .then()
                        .catch(function (err) {
                        _this.logger.error('FlagshipIO:flagshipIOPort_ send_hits', err);
                    });
                    break;
                }
                case 'update_context': {
                    _this.updateContext(portValue.context)
                        .then()
                        .catch(function (err) {
                        _this.logger.error('FlagshipIO:flagshipIOPort_ update_context', err);
                    });
                    break;
                }
                default: {
                    _this.logger.error('FlagshipIO:flagshipIOPort_ unrecognized portValue type', portValue);
                    break;
                }
            }
        });
    };
    return FlagshipIO;
}());
export { FlagshipIO };
