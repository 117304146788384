import DatadogPorts from './datadog';
import DomPorts, { scrollLeft, lockPageScroll, unlockPageScroll, } from './dom';
import ZendeskPorts from './zendesk';
import AnalyticsPorts from './analytics';
import ElementVisibilityObserver from './elementVisibilityObserver';
var Ports = function (_a) {
    var googleTagManager = _a.googleTagManager, datadog = _a.datadog, app = _a.app;
    var datadogPort = DatadogPorts(datadog);
    var domPort = DomPorts();
    var zendeskPorts = ZendeskPorts();
    var analyticsPort = AnalyticsPorts(googleTagManager);
    app.ports.sendErrorToDatadog.subscribe(datadogPort.sendError);
    app.ports.sendCustomActionToDatadog.subscribe(datadogPort.sendCustomAction);
    app.ports.scrollToClosestParent.subscribe(domPort.scrollToClosestParent);
    app.ports.sendEventToAnalytics.subscribe(analyticsPort.sendEvent);
    app.ports.showZendeskChat.subscribe(zendeskPorts.showZendeskChat);
    app.ports.scrollLeft.subscribe(scrollLeft);
    app.ports.lockPageScroll.subscribe(lockPageScroll);
    app.ports.unlockPageScroll.subscribe(unlockPageScroll);
    ElementVisibilityObserver(app);
};
export default Ports;
