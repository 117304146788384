/**
 * Automatically binds abstract Elm app which is using CookieBot module with all necessary callbacks
 * NOTE: this function shouldn't be async loaded/bundled since the cookieBot initialization is triggered within
 * Elm itself as one of the very first things
 *
 * @param logger - Logger instance
 * @param app - ElmApp instance
 */
export function mount(logger, app, onCookieConsentUpdated) {
    app.ports.cookieBotPort_.subscribe(function (portValue) {
        switch (portValue.type_) {
            case 'Init':
                init(portValue.args).then(function () {
                    var consent = getCurrentConsent();
                    onCookieConsentUpdated(consent);
                    try {
                        app.ports.cookieBotSubscription_.send({
                            consent: getCurrentConsent(),
                            type_: 'OnConsentUpdate',
                        });
                    }
                    catch (err) {
                        logger.warn('CookieBot@InitPort', 'cookieBotSubscription_ is not called in Elm code');
                    }
                });
                break;
        }
        onDialogDisplay().then(function () {
            window.wasCookiebotBannerDisplayed = true;
        });
        onCookieBotLoad()
            .then(function (consent) {
            onCookieConsentUpdated(consent);
            app.ports.cookieBotSubscription_.send({
                consent: consent,
                type_: 'OnConsentUpdate',
            });
        })
            .catch(function () {
            logger.warn('CookieBot@onCookieBotLoad', 'cookieBotSubscription_ is not called in Elm code');
        });
    });
}
/**
 * Adds callbacks to window global object.
 * These callbacks are directly sync-invoked by CookieBot code so are faster (and sync run) than window events
 *
 * @param dialogContainerId - dialog container id value
 */
function addWindowCallbacks(dialogContainerId) {
    // eslint-disable-next-line camelcase
    window.CookiebotCallback_OnDialogDisplay = function () {
        /**
         * This is the first callback which can be called right after the cookie-bot dialog container have been added as body
         * first child. It can be considered a safe entry point to apply dom-manipulation before the next Elm repaint cycle
         */
        // console.log('time at dialog display callback', window.performance.now())
        // console.log('body', Object.assign({}, document.body.childNodes))
        moveCookieBotDialog(dialogContainerId);
    }.bind(dialogContainerId);
    // eslint-disable-next-line camelcase
    window.CookiebotCallback_OnDialogInit = function () {
        /**
         * despite the name is called far away before the dialog element is effectively added to the DOM
         */
        // devConsoleLog('time at dialog init callback', window.performance.now())
        // devConsoleLog('body', Object.assign({}, document.body.childNodes))
    };
}
/**
 * Moves the cookie consent dialog as the last child
 *
 * @param dialogContainerId - id of the container
 */
function moveCookieBotDialog(dialogContainerId) {
    var dialogContainer = document.getElementById(dialogContainerId);
    if (dialogContainer) {
        // Moves the node from 1st to last DOM element
        document.body.appendChild(dialogContainer);
    }
}
/**
 * load cookie bot for me in async way
 *
 * @param args - Arguments
 */
export function init(args) {
    return new Promise(function (resolve, reject) {
        if ('Cookiebot' in window) {
            resolve();
        }
        else {
            // Adds sync callbacks into window object
            addWindowCallbacks((args === null || args === void 0 ? void 0 : args.dialogContainerId) || 'CybotCookiebotDialog');
            // Instantiate the promise right now to be sure to don't miss it
            var scriptNode = document.createElement('script');
            scriptNode.setAttribute('id', 'Cookiebot');
            scriptNode.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
            scriptNode.setAttribute('data-cbid', args.cookieBotId);
            scriptNode.setAttribute('async', 'true');
            scriptNode.onload = function () { return resolve(); };
            scriptNode.onerror = function () { return reject(); };
            var oldNode = document.getElementById('Cookiebot');
            oldNode
                ? oldNode.replaceWith(scriptNode)
                : document.head.appendChild(scriptNode);
        }
    });
}
/**
 * On dialog init event listener
 *
 * @returns a full-filled promise once the Cookiebot dialog have been initialized
 */
export function onDialogInit() {
    return new Promise(function (resolve) {
        window.addEventListener('CookiebotOnDialogInit', function (arg) {
            resolve(arg);
        });
    });
}
/**
 * On dialog show event listener
 *
 * @returns a full-filled promise once the Cookiebot dialog have been displayed to the user
 */
export function onDialogDisplay() {
    return new Promise(function (resolve) {
        window.addEventListener('CookiebotOnDialogDisplay', function (arg) {
            resolve(arg);
        });
    });
}
/**
 * Retrieves the current consent state in a sync way.
 * It's defaulted to necessary only whenever Cookiebot consent is not available so bind to 'onCookieBotLoad' to be notified
 * whenever the consent is changed
 *
 * @returns a valid CookieBotConsent object
 */
export function getCurrentConsent() {
    var _a, _b, _c, _d;
    return {
        isFirstConsent: window.wasCookiebotBannerDisplayed !== undefined
            ? window.wasCookiebotBannerDisplayed
            : false,
        marketing: ((_a = window.Cookiebot) === null || _a === void 0 ? void 0 : _a.consent.marketing) || false,
        necessary: ((_b = window.Cookiebot) === null || _b === void 0 ? void 0 : _b.consent.necessary) || true,
        preferences: ((_c = window.Cookiebot) === null || _c === void 0 ? void 0 : _c.consent.preferences) || false,
        statistics: ((_d = window.Cookiebot) === null || _d === void 0 ? void 0 : _d.consent.statistics) || false,
    };
}
/**
 * This will be triggered ONLY when the user had made a CB choice (previous or directly interacting with the bar).
 *
 * @returns a promise with consent object
 */
export function onCookieBotLoad() {
    return new Promise(function (resolve) {
        window.addEventListener('CookiebotOnLoad', function () {
            resolve(getCurrentConsent());
        });
    });
}
