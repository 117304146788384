/**
 * This class manages iubenda cookie consent lifecycle and also holds iubenda consent state
 *
 * Initially, the state is `not_initialized`, if you want to initialize, you need to explicitly call `initializeIubenda`
 *
 * Also, if you want to use elm's port, you need to set the app using `setApp`
 *
 */
var Iubenda = /** @class */ (function () {
    function Iubenda() {
        this.state = { type: 'not_initialized' };
    }
    /**
     * This function adds iubenda's configuration to the window variable and injects third-party iubenda script.
     *
     * @param onPreferenceExpressed - This callback will be called every time iubenda's preference is received.
     * This can happen either by the user clicking in accept or by loading from cookies if the user had accepted previously.
     *
     */
    Iubenda.prototype.initializeIubenda = function (onPreferenceExpressed) {
        window._iub = window._iub || [];
        window._iub.csConfiguration = this.getConfiguration(onPreferenceExpressed);
        this.injectIubendaScript();
    };
    Iubenda.prototype.setApp = function (app) {
        this.app = app;
    };
    Iubenda.prototype.getConfiguration = function (onPreferenceExpressed) {
        var _this = this;
        return {
            consentOnContinuedBrowsing: false,
            lang: 'es',
            perPurposeConsent: true,
            siteId: 2828421,
            whitelabel: false,
            cookiePolicyId: 49404346,
            banner: {
                acceptButtonColor: '#067F96',
                acceptButtonDisplay: true,
                backgroundColor: '#21283B',
                closeButtonDisplay: false,
                customizeButtonCaptionColor: '#21283B',
                customizeButtonColor: '#FFFFFF',
                customizeButtonDisplay: true,
                fontSizeBody: '12px',
                listPurposes: true,
                position: 'bottom',
                rejectButtonColor: '#067F96',
                rejectButtonDisplay: true,
            },
            callback: {
                // We need to be aware when the consent is given for the first time.
                // Because `onPreferenceExpressed` is triggered when the user consents and
                // when the page loads and the user previously consented
                onPreferenceFirstExpressed: function (preference) {
                    var _a;
                    onPreferenceExpressed(preference, true);
                    _this.state = { type: 'first_time_consented', preference: preference };
                    (_a = _this.app) === null || _a === void 0 ? void 0 : _a.ports.iubendaSubscription_.send({
                        type_: 'OnIubendaConsentExpressed',
                        isFirstConsent: true,
                        consent: {
                            analytics: preference.purposes[4],
                        },
                    });
                },
                onPreferenceExpressed: function (preference) {
                    var _a;
                    // This is needed because `onPreferenceFirstExpressed` and `onPreferenceExpressed`
                    // are both triggered in the first time the user consents
                    if (_this.state.type === 'first_time_consented') {
                        return;
                    }
                    onPreferenceExpressed(preference, false);
                    _this.state = { type: 'consented', preference: preference };
                    (_a = _this.app) === null || _a === void 0 ? void 0 : _a.ports.iubendaSubscription_.send({
                        type_: 'OnIubendaConsentExpressed',
                        isFirstConsent: false,
                        consent: {
                            analytics: preference.purposes[4],
                        },
                    });
                },
            },
        };
    };
    Iubenda.prototype.injectIubendaScript = function () {
        var script = document.createElement('script');
        script.setAttribute('src', '//cdn.iubenda.com/cs/iubenda_cs.js');
        script.setAttribute('async', 'true');
        document.head.appendChild(script);
    };
    return Iubenda;
}());
export { Iubenda };
