// webpack 4 doesn't treeshake lodash-es if imports *
import {cloneDeep, keys as _keys, map} from 'lodash-es';
const braintreeClient = require('braintree-web/client');
const braintreeHostedFields = require('braintree-web/hosted-fields');
const braintree3DSecureClient = require('braintree-web/three-d-secure');
let use3dSecure = false;
let hostedFieldInstance = null;
let braintreeClientInstance = null;

export default function braintreePorts(app) {
  const checkNewRelic = (error, message) => {
    if ('NREUM' in window) {
      NREUM.noticeError(new Error(`${message} ${error}`));
    }
  };
  app.ports.braintreeSetup.subscribe(function(token) {
    console.debug("Try to Initialize braintree with 3ds");
    if (braintreeClientInstance !== null) {
      braintreeClientInstance.teardown()
        .then(() => {
          initialize()
        })
        .catch(error => {
          checkNewRelic(error, 'Error @BraintreeSetup with 3DS Teardown:');
          app.ports.creditCardReady.send(false);
          sendErrorToElm("setupBraintreeClientInstance", error);
        })
    } else {
      initialize()
    }
    // Before next browser repaint
    function initialize() {
      window.requestAnimationFrame(() => {
        setTimeout(() => {
          setupBraintree3DS(token)
        }, 500)
      })
    }
  });

  // Make subscriptions to Elm's actions
  app.ports.payWithCreditCard.subscribe(() => {
    if (hostedFieldInstance !== null) {
      return hostedFieldInstance.tokenize(tokenized())
    }
  })

  app.ports.verify3DSCard.subscribe((options) => {
    if (window.threeDSecure === null) return;

    window.threeDSecure.verifyCard({
      amount: options.amount,
      nonce: options.nonce,
      bin: options.bin,
      challengeRequested: true,
      // email: options.email,
      // mobilePhoneNumber: options.phoneNumber,
      // billingAddress: {
      //     givenName: options.billingAddress.givenName,
      //     surname: options.billingAddress.surname,
      //     // phoneNumber: options.billingAddress.phoneNumber,
      //     // streetAddress: options.billingAddress.streetAddress,
      //     // locality: options.billingAddress.locality,
      //     // region: options.billingAddress.region,
      //     // postalCode: options.billingAddress.postalCode,
      //     // countryCodeAlpha2: options.billingAddress.countryCode
      // },
      onLookupComplete: (data, next) => {
        next()
      }
    })
      .then((response) => {
        // Handle response
        console.debug('Received nonce, payment attempt');
        app.ports.threeDSVerified.send({
          // this was copy/paste from domus - verify if we need to also do liability check here
          nonce: response.nonce,
          bin: response.details.bin,
          status: response.threeDSecureInfo.status
        })
      }).catch((error) => {
        // Handle error
        console.debug('3DS exception, try to fallback to plain braintree');
        checkNewRelic(cloneDeep(error), 'Error @Braintree 3DS verify card:');
        sendErrorToElm("sendThreeDSVerified", error);
      })
  });

  /*
    Braintree setup
    @params [String] braintreeToken - the token
   */
  const setupBraintree = braintreeToken => {
    use3dSecure = false
    braintreeClient.create({ authorization: braintreeToken }, braintreeClientReady)
  };
  const setupBraintree3DS = (braintreeToken) => {
    use3dSecure = true;
    braintreeClient
      .create({
        authorization: braintreeToken,
      })
      .then((clientInstance) => {
        braintreeClientInstance = clientInstance;
        return braintree3DSecureClient.create({
          version: 2, // Will use 3DS 2 whenever possible
          client: clientInstance,
          cardinalSDKConfig: {
            logging: {
              level: "off"
            }
          }
        });
      })
      .then((threeDSecureInstance) => {
        window.threeDSecure = threeDSecureInstance;
        braintreeClientReady(
          braintreeClientInstance == null
            ? new Error('Unable to initialize Braintree with 3dsecure')
            : null,
          braintreeClientInstance
        );
      })
      .catch(function(error) {
        //console.error('Error: ', err);
        sendErrorToElm("setupBraintree3DS", error);
        checkNewRelic(error, 'Error @BraintreeSetup with 3DS:');
        setupBraintree(braintreeToken);
      });
  };
  /*
    Callback of Braintree's setup
    @params [Object] err - the Braintree's error
    @params [Object] clientInstance - the Braintree's instance returned after setup
   */
  const braintreeClientReady = (error, clientInstance) => {
    // Die on setup error
    if (error) {
      checkNewRelic(error, 'Error @BraintreeClientReady:');
      sendErrorToElm("braintreeClientReady", error);
      return
    }
    braintreeClientInstance = clientInstance
    // Compose Iframes and style them
    if (hostedFieldInstance !== null) {
      hostedFieldInstance.teardown()
        .then(() => {
          hostedFieldInstance = null
          initializeHostedFields()
        })
        .catch(error => {
          app.ports.creditCardReady.send(false);
          checkNewRelic(error, 'Error @BraintreeClientReady Hosted fields Teardown:');
          sendErrorToElm("braintreeClientInstance", error);
        })
    } else {
      initializeHostedFields()
    }
    function initializeHostedFields() {
      braintreeHostedFields.create({
        client: clientInstance,
        styles: {
          input: 'form-field__text', // class name from Pyxis styles
        },
        fields: {
          number: {
            container: '#credit-card-number',
            placeholder: '4111 1111 1111 1111'
          },
          cvv: {
            container: '#credit-card-cvv',
            placeholder: '123'
          },
          expirationDate: {
            container: '#credit-card-expiration-date',
            placeholder: 'MM / YY'
          }
        }
      }, creditCardSettedUp)
    }
  };
  /*
    Callback of Braintree's DOM render
    @params [Object] err - the Braintree's error
    @params [Object] clientInstance - the Braintree's DOM
   */
  const creditCardSettedUp = (error, braintreeHostedFieldsInstance) => {
    if (error) {
      app.ports.creditCardReady.send(false);
      checkNewRelic(error, 'Error @BraintreeCreditCardSettedUp:');
      sendErrorToElm("creditCardSettedUp " + braintreeHostedFieldsInstance, error);
      return;
    }
    // Apply events to Braintree's DOM nodes
    braintreeHostedFieldsInstance.on('validityChange', braintreeValidationResponse('validityChange'))
    braintreeHostedFieldsInstance.on('focus', braintreeValidationResponse('focus'))
    braintreeHostedFieldsInstance.on('blur', braintreeValidationResponse('blur'))
    hostedFieldInstance = braintreeHostedFieldsInstance

    // Notify Payment app that the DOM can be now manipulated
    app.ports.creditCardReady.send(true)
  }


  /*
    Callback of Braintree's nodes validation
    @params [Object] response - the Braintree's validation response
   */
  const braintreeValidationResponse = (eventType) => {
    // Transform the Braintree response into an ELM compliant datatype
    // then fire it through the port
    return (response) =>
      app.ports.validateCreditCardFields.send(mapBraintreeResponse(response, eventType))
  };
  /*
    Callback of Braintree's nodes validation
    @params [Object] response - the Braintree's validation response
   */
  const mapBraintreeResponse = (response, eventType) => {
    let fields = response.fields
    let keys = _keys(fields)

    return map(keys, key => {
      let braintreeNode = fields[key]
      let id = braintreeNode.container.id
      let isValid = braintreeNode.isValid
      let isEmpty = braintreeNode.isEmpty
      let isFocused = braintreeNode.isFocused
      let blurred = eventType === 'blur' && key == response.emittedBy;

      return {
        id: id,
        isValid: isValid,
        isPristine: isEmpty,
        isFocused: isFocused,
        blurred: blurred
      }
    })
  };
  const tokenized = action => {
    return (tokenizeErr, payload) => {
      // Payment request has failed
      if (tokenizeErr) {
        checkNewRelic(tokenizeErr, 'Error @Braintree Tokenized:');
        sendErrorToElm("braintreeTokenizeError", tokenizeErr);
        return
      }
      // Payment nonce received. Send it through the port
      if (use3dSecure && "CreditCard" == payload.type) {
        app.ports.threeDSPayloadReceived.send({ nonce: payload.nonce, bin: payload.details.bin })
      } else {
        app.ports.nonceReceived.send(payload.nonce)
      }
    }
  };
  const sendErrorToElm = (context, error) => {
    let errorEvent = {
      context: context,
      error: error.message,
      code: error.code
    };

    if (error && error.details && error.details.originalError) {
      errorEvent.error = error.details.originalError.message;
      if (error.details.originalError.details
        && error.details.originalError.details.originalError
        && error.details.originalError.details.originalError.error) {
        errorEvent.error = error.details.originalError.details.originalError.error.message;
      }
    }
    if (errorEvent.error === undefined) {
      errorEvent.error = "";
    }

    app.ports.notifyErrorEvent.send(errorEvent);
  }
}
