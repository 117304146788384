import { datadogRum } from '@datadog/browser-rum';
export default (function (rumConfig) {
    function run() {
        datadogRum.init(rumConfig);
        datadogRum.startSessionReplayRecording();
    }
    function addError(error, extra) {
        datadogRum.addError(error, extra);
    }
    function addAction(name, data) {
        datadogRum.addAction(name, data);
    }
    return {
        run: run,
        addError: addError,
        addAction: addAction,
    };
});
