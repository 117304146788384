var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var Logger = /** @class */ (function () {
    /**
     * Constructor for log instance
     *
     * @param args - constuctor arguments
     */
    function Logger(args) {
        this.isPrivateLogEnvironment = args.isPrivateLogEnvironment;
        this.datadog = args.datadog;
    }
    /**
     * `console.log` wrapper, logs only when environment is not Production and not Staging
     *
     * @param codeLocation - where in your code this log has been emitted e.g. "Utils:devConsoleLog"
     * @param args - object to log
     */
    Logger.prototype.log = function (codeLocation) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (!this.isPrivateLogEnvironment) {
            console.log.apply(console, __spreadArray(["Log @".concat(codeLocation, ": ")], args, false));
        }
    };
    /**
     * `console.log` wrapper, logs only when environment is not Production and not Staging
     *
     * @param codeLocation - where in your code this error has been emitted e.g. "Utils:devConsoleLog"
     * @param args - object to log
     */
    Logger.prototype.warn = function (codeLocation) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        if (!this.isPrivateLogEnvironment) {
            console.warn.apply(console, __spreadArray(["Warn @".concat(codeLocation, ": ")], args, false));
        }
    };
    /**
     * `console.log` wrapper, logs only when environment is not Production and not Staging
     *
     * @param codeLocation - where in your code this error has been emitted e.g. "Utils:devConsoleLog"
     * @param args - object to log
     */
    Logger.prototype.error = function (codeLocation) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        this.datadog.addError("Error @".concat(codeLocation), args);
        if (!this.isPrivateLogEnvironment) {
            console.error.apply(console, __spreadArray(["Error @".concat(codeLocation, ": ")], args, false));
        }
    };
    return Logger;
}());
export default Logger;
