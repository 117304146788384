/**
 * Waits for an element satisfying selector to exist, then resolves promise with the element.
 * Useful for resolving race conditions.
 *
 * @param selector
 * @returns {Promise}
 */
function elementReady(selector, timeout = 1000) {
  return new Promise((resolve, reject) => {
    const element = document.querySelector(selector)
    if (element) {
      return resolve(element)
    }

    let timeoutId = null

    const observer = new MutationObserver((_) => {
      // Query for elements matching the specified selector
      let element = document.querySelector(selector)
      if (element) {
        // Clear the timeout
        clearTimeout(timeoutId)
        // return first element
        resolve(element)
        //Once we have resolved we don't need the observer anymore.
        observer.disconnect()
      }
    })

    if (timeout) {
      // Disconnect after timeout
      timeoutId = setTimeout(() => {
        resolve(null)
        observer.disconnect()
      }, timeout)
    }

    // Start observing for update
    observer.observe(document.documentElement, {
      childList: true,
      subtree: true,
    })
  })
}

export default {
  elementReady,
}
