var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import domUtils from './utils/dom';
export default (function () {
    function scrollToClosestParent(args) {
        var queryTimeout = 1000;
        domUtils.elementReady(args.childQuery, queryTimeout).then(function (element) {
            if (element) {
                var parent_1 = element.closest(args.parentQuery);
                scrollToElement(parent_1);
            }
        });
    }
    function scrollToElement(element) {
        if (!element || !element.scrollIntoView)
            return;
        var scrollOptions = {
            block: 'start',
            inline: 'nearest',
            behavior: 'smooth',
        };
        element.scrollIntoView(scrollOptions);
    }
    return {
        scrollToClosestParent: scrollToClosestParent,
    };
});
export function scrollOf(_a) {
    var delta = _a.delta, direction = _a.direction, elementID = _a.elementID;
    var element = document.getElementById(elementID);
    switch (direction) {
        case 'left': {
            return element && (element.scrollLeft += delta);
        }
        case 'right': {
            return element && (element.scrollLeft -= delta);
        }
        case 'top': {
            return element && (element.scrollTop += delta);
        }
        case 'bottom': {
            return element && (element.scrollTop -= delta);
        }
    }
}
export function scrollLeft(args) {
    return scrollOf(__assign(__assign({}, args), { direction: 'left' }));
}
/**
 * Locks page scroll
 */
export function lockPageScroll() {
    document.documentElement.classList.add('scroll-locked');
}
/**
 * Unlocks page scroll
 */
export function unlockPageScroll() {
    document.documentElement.classList.remove('scroll-locked');
}
