var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var GoogleTagManager = /** @class */ (function () {
    function GoogleTagManager(args) {
        this.gtmId = args.gtmId;
        this.gtmAuth = args.gtmAuth;
        this.gtmPreview = args.gtmPreview;
        this.isConsentModeEnabled = args.isConsentModeEnabled;
        this.loadGtagScript();
        window.dataLayer = window.dataLayer || [];
        window.gtag =
            window.gtag ||
                function () {
                    // eslint-disable-next-line prefer-rest-params
                    window.dataLayer.push(arguments);
                };
        this.gtag = window.gtag;
        this.setDefaultConsentValues();
        // Initial gtm events
        window.dataLayer.push({
            event: 'gtm.js',
            'gtm.start': new Date().getTime(),
        });
        this.gtag('js', new Date());
        this.gtag('config', this.gtmId);
    }
    /**
     * Sends events generated by the app
     */
    GoogleTagManager.prototype.sendEvent = function (event) {
        window.dataLayer.push(__assign({ event: event.name }, event.payload));
    };
    /**
     * Pushes the default consent variables to gtm when consent mode is enabled
     */
    GoogleTagManager.prototype.setDefaultConsentValues = function () {
        if (!this.isConsentModeEnabled)
            return;
        if (window._DEFAULT_CONSENT_HAS_BEEN_PUSHED === undefined) {
            this.gtag('consent', 'default', {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line camelcase
                ad_personalization: 'denied',
                // eslint-disable-next-line camelcase
                ad_storage: 'denied',
                // eslint-disable-next-line camelcase
                ad_user_data: 'denied',
                // eslint-disable-next-line camelcase
                analytics_storage: 'denied',
                // eslint-disable-next-line camelcase
                functionality_storage: 'denied',
                // eslint-disable-next-line camelcase
                personalization_storage: 'denied',
                // eslint-disable-next-line camelcase
                security_storage: 'granted',
                // eslint-disable-next-line camelcase
                wait_for_update: 500,
            });
            this.gtag('set', 'ads_data_redaction', true);
            this.gtag('set', 'url_passthrough', true);
            window._DEFAULT_CONSENT_HAS_BEEN_PUSHED = true;
        }
    };
    GoogleTagManager.prototype.loadGtagScript = function () {
        var _this = this;
        var script = document.createElement('script');
        script.setAttribute('id', GoogleTagManager.SCRIPT_TAG_ID);
        script.setAttribute('src', "https://www.googletagmanager.com/gtm.js?id=".concat(this.gtmId, "&gtm_auth=").concat(this.gtmAuth, "&gtm_preview=").concat(this.gtmPreview));
        script.setAttribute('async', 'true');
        script.onload = function () {
            _this.gtag = window.gtag;
        };
        document.head.appendChild(script);
    };
    GoogleTagManager.SCRIPT_TAG_ID = 'googleTagManager';
    return GoogleTagManager;
}());
export { GoogleTagManager };
