var SUPPORTED_PRIVACY_CONSENT_PROVIDERS = ['COOKIE_BOT', 'IUBENDA'];
export function getInitialConfig() {
    var graphqlFederationUrl = parseEnvVar('GRAPHQL_FEDERATION_URL', process.env.GRAPHQL_FEDERATION_URL);
    var supportedLocales = parseEnvVar('SUPPORTED_LOCALES', process.env.SUPPORTED_LOCALES)
        .split(',')
        .map(function (s) { return s.trim(); });
    return {
        appEnv: parseEnvVar('ENV', process.env.ENV),
        flagshipConfig: {
            apiKey: parseEnvVar('FLAGSHIP_API_KEY', process.env.FLAGSHIP_API_KEY),
            environmentId: parseEnvVar('FLAGSHIP_ENVIRONMENT_ID', process.env.FLAGSHIP_ENVIRONMENT_ID),
        },
        isPrivateLogEnvironment: parseBooleanEnvVar('IS_PRIVATE_LOG_ENVIRONMENT', process.env.IS_PRIVATE_LOG_ENVIRONMENT),
        gtmId: parseEnvVar('GTM_ID', process.env.GTM_ID),
        gtmAuth: parseEnvVar('GTM_AUTH', process.env.GTM_AUTH),
        gtmPreview: parseEnvVar('GTM_PREVIEW', process.env.GTM_PREVIEW),
        graphqlFederationUrl: graphqlFederationUrl,
        assetsBaseUrl: parseEnvVar('ASSETS_BASE_URL', process.env.ASSETS_BASE_URL),
        homeBaseUrl: parseEnvVar('HOME_BASE_URL', process.env.HOME_BASE_URL),
        supportedLocales: supportedLocales,
        defaultLocale: parseEnvVar('DEFAULT_LOCALE', process.env.DEFAULT_LOCALE),
        datadogRUMConfig: rumConfig(graphqlFederationUrl),
        privacyConsentProvider: getPrivacyConsentProviderFromEnv(),
        cookieBotId: parseEnvVar('COOKIE_BOT_ID', process.env.COOKIE_BOT_ID),
    };
}
/**
 * Check if the active privacy consent provider is cookie bot
 *
 * @returns true when is cookie bot
 */
export function isCookieBotEnabled(values) {
    return values.privacyConsentProvider === 'COOKIE_BOT';
}
function getPrivacyConsentProviderFromEnv() {
    var value = parseEnvVar('PRIVACY_CONSENT_PROVIDER', process.env.PRIVACY_CONSENT_PROVIDER);
    if (isPrivacyConsentProvider(value)) {
        return value;
    }
    throw new Error("Unsupported privacy consent provider: ".concat(value));
}
function isPrivacyConsentProvider(value) {
    return SUPPORTED_PRIVACY_CONSENT_PROVIDERS.includes(value);
}
function rumConfig(graphqlFederationUrl) {
    var rumEnabled = parseEnvVar('DATADOG_RUM_ENABLED', process.env.DATADOG_RUM_ENABLED);
    if (rumEnabled === 'false')
        return null;
    return {
        applicationId: parseEnvVar('DATADOG_RUM_APPLICATION_ID', process.env.DATADOG_RUM_APPLICATION_ID),
        clientToken: parseEnvVar('DATADOG_RUM_CLIENT_TOKEN', process.env.DATADOG_RUM_CLIENT_TOKEN),
        site: parseEnvVar('DATADOG_RUM_SITE', process.env.DATADOG_RUM_SITE),
        service: 'es-fe-funnel',
        env: parseEnvVar('ENV', process.env.ENV),
        version: process.env.APP_VERSION,
        allowedTracingUrls: [
            {
                match: graphqlFederationUrl,
                propagatorTypes: ['tracecontext'],
            },
        ],
        // Static config
        sampleRate: 100,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackInteractions: true,
        trackFrustrations: false,
        defaultPrivacyLevel: 'mask-user-input',
    };
}
// `process.env` is not a common javascript object, so unfortunately we cannot
// access the value using `{ something } = process.env` or `process.env['something']`
// Reference: https://nextjs.org/docs/basic-features/environment-variables
function parseEnvVar(key, envValue) {
    if (!envValue) {
        throw new Error("Couldn't find environment variable: ".concat(key));
    }
    return envValue;
}
function parseBooleanEnvVar(key, envValue) {
    var value = parseEnvVar(key, envValue);
    if (!['true', 'false'].includes(value)) {
        throw new Error("Invalid value: ".concat(value, ", expecting 'true' or 'false'"));
    }
    return value === 'true';
}
